.nav-links-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
@media(max-width: 767px) {
  .nav-links-links {
    display: none;
  }
}
