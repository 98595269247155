span, h1, h2 {
  user-select: none;
}

.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
}
.home-text {
  color: #27272D;
  font-size: 30px;
  font-family: Inter;
}
.home-desktop-menu {
  display: flex;
}
.home-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.home-right {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-work-with-us {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-000);
}
.home-work-with-us:hover {
  opacity: 0.5;
}
.home-text04 {
  display: flex;
  font-size: 24px;
}
.home-image01 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-work-with-us1 {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-000);
}
.home-work-with-us1:hover {
  opacity: 0.5;
}
.home-text05 {
  display: flex;
  font-size: 24px;
}
.home-image02 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-700);
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding {
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  border-color: #61616B;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-text06 {
  color: #27272D;
  font-size: 30px;
  font-family: Inter;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-middle {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-work-with-us2 {
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-000);
}
.home-work-with-us2:hover {
  opacity: 0.5;
}
.home-text07 {
  display: flex;
  font-size: 24px;
}
.home-hero {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: 140px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text08 {
  color: var(--dl-text-color-1);
  width: 100%;
  font-size: 54px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 400;
}
.home-image03 {
  width: 24px;
  height: 40px;
  display: flex;
  object-fit: cover;
}
.home-hero-image {
  width: 100%;
  display: flex;
  align-items: flex-end;
  background-color: transparent;
  /*
  padding-top: var(--dl-space-space-threeunits);
  padding-left: 180px;
  padding-right: 180px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #3399FF;
   */
}
.home-image04 {
  width: 100%;
  object-fit: cover;
}
.home-who {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 320px;
}
.home-header1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: column;
}
.home-heading {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-text09 {
  width: 100%;
  font-size: 54px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
}
.home-text10 {
  color: var(--dl-text-color-1);
  width: 100%;
  font-size: 24px;
  max-width: 800px;
  line-height: 43px;
}
.home-button1 {
  font-size: 24px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 70px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-000);
  margin-bottom: 20px;
}
.home-preview {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: 170px;
  padding-right: 170px;
  flex-direction: row;
}
.home-back-image {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 527px;
  align-items: flex-start;
  flex-direction: column;
  background-color: transparent;
  /* #E5FE58*/
}
.home-image05 {
  object-fit: cover;
  margin-left: -160px;
}
.home-front-image {
  top: 0px;
  right: 0px;
  width: 646px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-image06 {
  object-fit: cover;
  margin-left: -160px;
}
.home-section {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 135px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 135px;
  background-color: #ffffff;
}
.home-case-studies {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  border-color: #000000;
  flex-direction: column;
  padding-bottom: 130px;
  border-bottom-width: 1px;
}
.home-caption {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.home-text11 {
  color: #000000;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-gallery {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}
.home-image07 {
  width: 560px;
  height: 416px;
  object-fit: cover;
}
.home-case {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-heading1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text12 {
  color: var(--dl-text-color-1);
  font-size: 30px;
  line-height: 36px;
}
.home-text13 {
  color: var(--dl-text-color-1);
  font-size: 18px;
}
.home-row1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
}
.home-image08 {
  width: 560px;
  height: 628px;
  object-fit: cover;
}
.home-case1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-heading2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text14 {
  color: var(--dl-text-color-1);
  font-size: 30px;
  line-height: 36px;
}
.home-text15 {
  color: var(--dl-text-color-1);
  font-size: 18px;
}
.home-row2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home-image09 {
  width: 560px;
  height: 416px;
  object-fit: cover;
}
.home-case2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-heading3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text16 {
  color: var(--dl-text-color-1);
  font-size: 30px;
  line-height: 36px;
}
.home-text17 {
  color: var(--dl-text-color-1);
  font-size: 18px;
}
.home-row3 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-image10 {
  width: 560px;
  height: 628px;
  object-fit: cover;
}
.home-case3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-heading4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text18 {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
}
.home-text19 {
  color: #000000;
  font-size: 18px;
}
.home-row4 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home-image11 {
  width: 560px;
  height: 416px;
  object-fit: cover;
}
.home-case4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
}
.home-heading5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text20 {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
}
.home-text21 {
  color: #000000;
  font-size: 18px;
}
.home-row5 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-image12 {
  width: 560px;
  height: 628px;
  object-fit: cover;
}
.home-case5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-heading6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text22 {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
}
.home-text23 {
  color: #000000;
  font-size: 18px;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-locations {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-location {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 270px;
  align-items: flex-start;
  flex-direction: column;
}
.home-title {
  color: rgb(39, 39, 45);
  font-size: 30px;
}
.home-details {
  color: rgb(39, 39, 45);
  font-size: 16px;
  line-height: 24px;
}
@media(max-width: 1200px) {
  .home-container1 {
    align-items: flex-end;
    flex-direction: row;
  }
  .home-text03 {
    letter-spacing: 1.5px;
  }
  .home-work-with-us {
    background-color: var(--dl-color-primary-000);
  }
  .home-text04 {
    font-size: 24px;
  }
  .home-text08 {
    color: rgb(39, 39, 45);
  }
  .home-hero-image {
    width: 100%;
    background-color: transparent;
  }
  .home-text09 {
    font-size: 54px;
  }
  .home-text10 {
    color: rgb(39, 39, 45);
    font-size: 24px;
    line-height: 43px;
  }
  .home-button1 {
    background-color: var(--dl-color-primary-000);
  }
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    gap: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-image {
    display: none;
  }
  .home-text04 {
    line-height: 30px;
  }
  .home-text05 {
    line-height: 30px;
  }
  .home-text07 {
    line-height: 30px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text08 {
    font-size: 32px;
  }
  .home-image03 {
    display: none;
  }
  /*
  .home-hero-image {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
  }
   */
  .home-who {
    gap: var(--dl-space-space-fourunits);
    margin-bottom: 50px;
    padding-bottom: 0px;
  }
  .home-header1 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    gap: var(--dl-space-space-unit);
  }
  .home-text09 {
    font-size: 32px;
  }
  .home-text10 {
    font-size: 16px;
    line-height: 28px;
  }
  .home-button1 {
    font-size: 16px;
  }
  .home-preview {
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-back-image {
    width: 80%;
    height: 100%;
  }
  .home-image05 {
    width: 100%;
  }
  .home-front-image {
    top: auto;
    left: 24px;
    right: auto;
    bottom: -729px;
  }
  .home-image06 {
    width: 100%;
  }
  .home-section {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-case-studies {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-caption {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text11 {
    font-size: 12px;
  }
  .home-gallery {
    gap: var(--dl-space-space-threeunits);
  }
  .home-row {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image07 {
    width: 100%;
    height: 210px;
  }
  .home-case {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-top: 0px;
  }
  .home-heading1 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text12 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text13 {
    font-size: 16px;
  }
  .home-row1 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image08 {
    width: 100%;
    height: 210px;
  }
  .home-case1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding-top: 0px;
  }
  .home-heading2 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text14 {
    font-size: 24px;
  }
  .home-text15 {
    font-size: 16px;
  }
  .home-row2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image09 {
    width: 100%;
    height: 210px;
  }
  .home-case2 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-top: 0px;
  }
  .home-heading3 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text16 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text17 {
    font-size: 16px;
  }
  .home-row3 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image10 {
    width: 100%;
    height: 210px;
  }
  .home-case3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding-top: 0px;
  }
  .home-heading4 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text18 {
    font-size: 24px;
  }
  .home-text19 {
    font-size: 16px;
  }
  .home-row4 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image11 {
    width: 100%;
    height: 210px;
  }
  .home-case4 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    margin-top: 0px;
  }
  .home-heading5 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text20 {
    font-size: 24px;
    line-height: 30px;
  }
  .home-text21 {
    font-size: 16px;
  }
  .home-row5 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    flex-direction: column;
  }
  .home-image12 {
    width: 100%;
    height: 210px;
  }
  .home-case5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    padding-top: 0px;
  }
  .home-heading6 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-text22 {
    font-size: 24px;
  }
  .home-text23 {
    font-size: 16px;
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-locations {
    padding-top: var(--dl-space-space-threeunits);
    border-color: var(--dl-color-gray-black);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    border-bottom-width: 1px;
  }
  .home-title {
    font-size: 24px;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-work-with-us {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text04 {
    display: none;
  }
  .home-image01 {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .home-burger-menu {
    display: flex;
    align-self: center;
  }
  .home-work-with-us1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text05 {
    display: none;
  }
  .home-image02 {
    width: 16px;
    height: 14px;
    display: flex;
  }
  .home-branding {
    max-width: 80%;
  }
  .home-work-with-us2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image04 {
    height: 220px;
  }
  .home-who {
    margin-bottom: 0px;
  }
  .home-front-image {
    width: 85%;
    bottom: -500px;
    z-index: 100;
    position: static;
    align-self: center;
    margin-top: -48px;
  }
}
@media(max-width: 479px) {
  .home-text05 {
    display: none;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-image05 {
    margin-left: -120px;
  }
  .home-front-image {
    bottom: -419px;
  }
  .home-image06 {
    margin-left: -120px;
  }
}
